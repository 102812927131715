import { Input } from '../Input'
import { DropdownItem } from './DropdownItem'
import { DropdownChangeEvent } from './DropdownChangeEvent'
import { DropdownDataProp } from './DropdownDataProp';
import { useState, useEffect, ChangeEvent, useRef, useCallback } from 'react';

interface SearchableDropdownStyles {
  component?: string,
  field?: string,
  list?: string,
}

interface SearchableDropdownProps {
  data: DropdownDataProp[],
  defaultSelectText?: string,
  name: string,
  firstItemSelected?: boolean,
  onChange?: (changeEvent: DropdownChangeEvent) => void,
  value?: string,
  selectedIndex?: number,
  required?: boolean,
  openAbove?: boolean,
  style?: SearchableDropdownStyles,
}

export const SearchableDropdown: React.FC<SearchableDropdownProps> = ({data, defaultSelectText="Select...", name, onChange, value, selectedIndex, required=false, openAbove=false, style}) => {
  const [filteredData, setFilteredData] = useState(data);
  const [selectedValue, setSelectedValue] = useState(defaultSelectText);
  const [focus, setFocus] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleHideDropdown = useCallback((event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if(selectedValue === '') {
          setSelectedValue(defaultSelectText)
        }

        // The ref is the whole containing div, which isn't what's focused, 
        // the input is. so, we will focus on the div container and then
        // blur to give the illusion that the escape key deselects the 
        // whole dropdown component
        ref.current?.focus();
        ref.current?.blur();

        setFocus(false);
      }
    },[defaultSelectText, selectedValue]);
  
  const handleClickOutside = useCallback((event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      if(selectedValue === '') {
        setSelectedValue(defaultSelectText)
      }
      setFocus(false);
    }
  }, [defaultSelectText, selectedValue]);
  
  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    window.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      window.removeEventListener("click", handleClickOutside, true);
    };
  },[handleClickOutside, handleHideDropdown]);

  useEffect(() => {
    if(selectedValue !== defaultSelectText){
      setFilteredData(data.filter(item => 
        item.value.toLowerCase().startsWith(selectedValue.toLowerCase()) &&
        item.value !== selectedValue
        ));
      if(onChange && selectedValue) onChange({currentTarget: {name, value: selectedValue, index: 0}})
    }
  }, [data, defaultSelectText, name, onChange, selectedValue]);

  const focusEntered = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.select();
    if(e.currentTarget.value === defaultSelectText){
      setSelectedValue('');
    }
    setFocus(true);
  }

  const changeDropdownValue = (item: string | DropdownDataProp, index: number) => {
    setSelectedValue((item as DropdownDataProp).value);
    setFocus(false);
  }

  const filterResults = (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedValue(e.currentTarget.value);
  }

  return (
    <div tabIndex={0} className={`inline-block ${style?.component || ''}`} ref={ref}>
        <Input 
          name={name} 
          value={selectedValue}
          type='text' 
          className={`border border-black bg-gray-50 text-black font-semibold py-2 px-4 min-w-full inline-flex items-center ${style?.field || ''}`} 
          onChange={filterResults} 
          onFocus={focusEntered}
          required={required}
          autoComplete='off'
        />
          {focus && <ul className={`z-10 absolute text-gray-700 border-2 overflow-y-auto max-h-80 ${openAbove && 'bottom-full'} ${style?.list || ''}`}>
              {filteredData.map((item, index) => 
                <DropdownItem item={item} index={index} key={index} updateDropdownValue={changeDropdownValue}/>
              )}
          </ul>}
    </div>
  );
}
