import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { usePaymentApplicationContext } from '../context/PaymentApplicationContext';
import { RepOffice } from '../model/RepOffice';
import { Dropdown } from './Dropdown/Dropdown';
import HJLogo  from '../images/HJ_logo.png'
import { HJButton } from './Button/HJButton';
import { SearchableDropdown } from './Dropdown/SearchableDropdown';

interface OfficeSelectorProps {
    officeData: RepOffice[],
    setRepOffice: Dispatch<SetStateAction<RepOffice | undefined>>
}

export const OfficeSelector: React.FC<OfficeSelectorProps> = ({officeData, setRepOffice}) => {
    const [selectedOffice, setSelectedOffice] = useState<string>()
    const { dispatch } = usePaymentApplicationContext();

    useEffect(() => {
        if(officeData.length === 1){
            setRepOffice(officeData[0])
            dispatch({type: 'setRepOffice', data: officeData[0]})
        }
    }, [officeData, setRepOffice, dispatch])

    const getOfficeDisplayStrings = () => {
        let sortedNames = [...officeData].sort();
        return sortedNames.map(i => ({value: i.officeName})).sort((a,b) => a.value.localeCompare(b.value));
    }

    const selectOffice = () => {
        let office = officeData.find(item => item.officeName === selectedOffice)
        if(office){
            setRepOffice(office)
            dispatch({type: 'setRepOffice', data: office})
        }
    }

    return (
        <div>
            <div className='flex justify-evenly text-xl nav sticky bg-gray-200 top-0'>
                <img src={HJLogo} className='h-16 object-contain p-2' alt='Herff Jones Logo'/>
                <p className='my-auto'>Welcome, {officeData[0].username}</p>
            </div>
            <div className='flex flex-col items-center justify-center h-64'>
                <p className='pb-3'>Select an office to submit an ACH for:</p>
                <SearchableDropdown data={getOfficeDisplayStrings()} 
                                    name={'office'} 
                                    onChange={e => setSelectedOffice(e.currentTarget.value)}
                />
                <HJButton clickHandler={selectOffice}>Select</HJButton>
            </div>
        </div>
    );
}